import { mapActions } from 'vuex';

/**
 * Mixin to share common code for fetching Data query result..
 */
const FetchDataQueryApiMixin = {

  data() {
    return {
      dataQueryDetailList: [],
      paginationMetaData: {},
    };
  },

  methods: {
    ...mapActions(['findQueryDetailForAdmin', 'fetchPaginationMetaData', 'fetchNextOrPreviousItems']),

    /**
     * Method to fetch query details.
     */
    async fetchDataQueryDetailsForAdmin(dataQueryId) {
      console.log(
        '------------ fetching fetchDataQueryDetailsForAdmin ********** ',
        dataQueryId,
      );
      this.dataQueryDetailList = await this.findQueryDetailForAdmin(dataQueryId);
      if (this.dataQueryDetailList) {
        this.calculateItemsPerPage(this.dataQueryDetailList.length);
      }
      console.log(
        '------------ fetchDataQueryDetailsForAdmin ********** ',
        this.dataQueryDetailList,
      );
    },

    /**
     * Method to fetch next or previous items on selection of pagination buttons.
     */
    async findNextOrPreviousItems(nextOrPreviousUrl, dataQueryIdParameter) {
      console.log(
        '------------ fetching findNextOrPreviousItems ------------ ',
        nextOrPreviousUrl,
        ' : ',
        dataQueryIdParameter,
      );
      if (nextOrPreviousUrl && dataQueryIdParameter) {
        const response = await this
          .fetchNextOrPreviousItems(
            { nextOrPreviousUrl, dataQueryIdParameter },
          );
        console.log(
          '------------ findNextOrPreviousItems response ********** ',
          response,
        );
        if (response) {
          this.dataQueryDetailList = JSON.parse(response.dataItems);
          this.paginationMetaData = JSON.parse(response.paginationMetaData);
        }
        console.log(
          '------------ findNextOrPreviousItems response ********** ',
          response,
        );
      }
    },

    /**
     * Fetch pagination meta data.
     *
     * @param {*} restUrlParam
     * @param {*} dataQueryIdForApi
     * @param {*} isDownloadReport
     */
    async initializePaginationMetaData(restUrlParam, dataQueryIdForApi, isDownloadReport) {
      console.log('=============== fetchdata : fetchPaginationMetaData START ============== ', restUrlParam, dataQueryIdForApi, isDownloadReport);
      this.paginationMetaData = await this.fetchPaginationMetaData(
        {
          restUrlParam,
          dataQueryIdForApi,
        },
      );
      console.log('=============== fetchPaginationMetaData : this.paginationMetaData ==============', this.paginationMetaData);
      if (this.paginationMetaData && Object.prototype.hasOwnProperty.call(this.paginationMetaData, 'ResultsTotal')) {
        if (!isDownloadReport) {
          this.calculateItemsPerPage(this.paginationMetaData.ResultsTotal);
        }
      } else if (this.paginationMetaData) {
        console.log('----Data withtout pagination metadata -----------', this.paginationMetaData);
        if (Object.prototype.toString.call(this.paginationMetaData) === '[object Array]') {
          console.log('---- Is Array -----------', this.paginationMetaData);
          this.dataQueryDetailList = [...this.paginationMetaData];
          this.paginationMetaData = { ResultsTotal: this.paginationMetaData.length };
          if (!isDownloadReport) {
            this.calculateItemsPerPage(0);
          }
        } else {
          console.log('---- Object -----------', this.paginationMetaData);
          (this.dataQueryDetailList).push(this.paginationMetaData);
          this.paginationMetaData = { ResultsTotal: 1 };
        }
      }
      console.log('=============== fetchdata : fetchPaginationMetaData END ============== ');
    },

    /**
     * Method to fetch query details from rest api.
     */
    async fetchDataQueryDetailsFromApi(restUrlParam, dataQueryIdForApi, isDownloadReport) {
      console.log(' *** fetchDataQueryDetailsFromApi call *** >  ', this.paginationMetaData);

      // fetch pagination data for furter calcuation.
      await this.initializePaginationMetaData(restUrlParam, dataQueryIdForApi, isDownloadReport);

      console.log(' *** After initi call *** >  ', this.paginationMetaData);

      if (this.paginationMetaData && Object.prototype.hasOwnProperty.call(this.paginationMetaData, 'ResultsTotal')) {
        console.log(
          '------------ fetchDataQueryDetailsFromApi 2222 ********** ',
        );

        console.log('this.paginationMetaData 3333: ', this.paginationMetaData);

        const { Links: { First: firstPageLink = '' } = '' } = this.paginationMetaData;

        console.log(' firstPageLink : ', firstPageLink);

        console.log(' >>> dataQueryDetailList : ', this.dataQueryDetailList);

        console.log('--------firstPageLink ------', firstPageLink);
        if (firstPageLink) {
          const indexOfApiSearch = (firstPageLink).indexOf('APISearch');
          if (indexOfApiSearch !== -1) {
            console.log('--------indexOfApiSearch ------', indexOfApiSearch);
            const initialBaseUrl = (firstPageLink).substring(0, indexOfApiSearch);
            let itemsPerPageCount = 0;
            if (isDownloadReport) {
              itemsPerPageCount = this.paginationMetaData.ResultsTotal;
            } else {
              itemsPerPageCount = this.itemsPerPage;
            }
            const newFirstPageUrl = `${initialBaseUrl}All$page=1$page_size=${itemsPerPageCount}`;
            console.log('--------newFirstPageUrl ------', newFirstPageUrl);
            if (newFirstPageUrl) {
              await this.findNextOrPreviousItems(newFirstPageUrl, dataQueryIdForApi);
            }
          }
        }
      }
    },
  },
};

export default FetchDataQueryApiMixin;

<template>
  <div>
    <GhmDataTable
      :dataList="dataQueryDetailList"
      :tableType="APP_DATATABLE_TYPE_ADMIN_DATA_QUERY_VIEW"
      :totalNumberOfItemsAvailable="paginationMetaData.ResultsTotal"
      :itemsPerPageProps="itemsPerPage"
      :itemsPerPageOptionsProps="itemsPerPageOptions"
      :isSqlType="isSqlType"
      @OnChangeOfOptionEmitter="optionPaginationEmitter"
      @ItemsPerPageEmitter="itemsPerPageEmitter"
    >
     <template v-slot:addNewRowButton>
          {{ displayNameAsPageMsg }}
     </template>
    </GhmDataTable>
  </div>
</template>
<script>

import Constants from '@/constants';
import GhmDataTable from '@/components/app/GhmDataTable.vue';
import ItemsPerPageMixin from '@/plugins/ItemsPerPageMixin';
import DataQueryMixin from '@/plugins/DataQueryMixin';

export default {

  mixins: [ItemsPerPageMixin, DataQueryMixin],

  data() {
    return {
      dataQueryDetailList: [],
      paginationMetaData: {},
      dataQueryIdParameter: 0,
      APP_DATATABLE_TYPE_ADMIN_DATA_QUERY_VIEW: Constants.APP_DATATABLE_TYPE_ADMIN_DATA_QUERY_VIEW,
    };
  },

  components: {
    GhmDataTable,
  },

};
</script>
<style scoped>
</style>

import { render, staticRenderFns } from "./UserQueryDetails.vue?vue&type=template&id=b00e4568&scoped=true&"
import script from "./UserQueryDetails.vue?vue&type=script&lang=js&"
export * from "./UserQueryDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b00e4568",
  null
  
)

export default component.exports
import { mapActions } from 'vuex';
import FetchDataQueryApiMixin from '@/plugins/FetchDataQueryApiMixin';

/**
 * Mixin to share common code for fetching Data query result..
 */
const DataQueryMixin = {

  mixins: [FetchDataQueryApiMixin],

  data() {
    return {
      dataQueryDetailList: [],
      paginationMetaData: {},
      dataQueryIdParameter: 0,
      displayNameAsPageMsg: '',
      isSqlType: false,
    };
  },

  /**
   * Initialize and get the data query list.
   */
  async mounted() {
    console.log('---------- mounted route 11 ----------- : ', this.$route);
    console.log('---------- mounted route 11 ----------- : ', this.$route.params.dataQueryId);
    if (this.$route && this.$route.params) {
      console.log('-------- NAME ------------ ', this.$route.params.dataQueryName);
      this.displayNameAsPageMsg = this.$route.params.dataQueryName;
      if (this.$route.params.dataQueryId) {
        console.log('---------- mounted route dataQuery ----------- : ', this.$route.params.dataQueryId);
        this.isSqlType = true;
        await this.fetchDataQueryDetailsForAdmin(this.$route.params.dataQueryId);
      } else if (this.$route.params.restUrlParam && this.$route.params.dataQueryIdForApi) {
        console.log('---------- mounted route restApi ----------- : ', this.$route.params.restUrlParam);
        this.isSqlType = false;
        this.dataQueryIdParameter = this.$route.params.dataQueryIdForApi;
        /* this.fetchDataQueryDetailsFromApi(
          this.$route.params.restUrlParam, this.$route.params.dataQueryIdForApi,
        ); */

        // Call pagination meta data to calculate initial size for pagination.
        await this.initializePaginationMetaData(this.$route.params.restUrlParam,
          this.$route.params.dataQueryIdForApi, false);
      }
    }
  },

  methods: {
    ...mapActions(['findQueryDetailForAdmin', 'fetchPaginationMetaData', 'fetchNextOrPreviousItems']),

    /**
     * Emitter get trigger when user click on next/previous button on pagination bar.
     */
    async optionPaginationEmitter(options) {
      console.log('-------DataQueryMixiing : optionPaginationEmitter : options------START ', options, ' : ', this.dataQueryIdParameter);
      if (options && Object.prototype.hasOwnProperty.call(this.paginationMetaData, 'Page')) {
        let nextOrPreviousUrl;
        if (options.page > 0) {
          console.log('-------options.page------', options.page);
          console.log('-------this.paginationMetaData.Page------', this.paginationMetaData);
          if (this.paginationMetaData.Page_Size !== options.itemsPerPage) {
            console.log('------- item per page changed ------', options.page);
            const firstPageLink = this.paginationMetaData.Links.First;
            console.log('------- firstPageLink ------', firstPageLink);
            if (firstPageLink) {
              const indexOfPageSize = (firstPageLink).indexOf('$page_size=');
              console.log('------- indexOfPageSize ------', indexOfPageSize);
              if (indexOfPageSize !== -1) {
                const pageSizeSubstring = (firstPageLink)
                  .substring(indexOfPageSize, firstPageLink.length);
                console.log('------- pageSizeSubstring ------', pageSizeSubstring);
                nextOrPreviousUrl = (firstPageLink)
                  .replace(pageSizeSubstring, (`$page_size=${options.itemsPerPage}`));
                console.log('------- nextOrPreviousUrl ------', nextOrPreviousUrl);
              }
            }
          } else if (options.page > this.paginationMetaData.Page) {
            console.log('-------Next------', options.page);
            nextOrPreviousUrl = this.paginationMetaData.Links.Next;
          } else if (options.page < this.paginationMetaData.Page) {
            console.log('-------Previous------', options.page);
            nextOrPreviousUrl = this.paginationMetaData.Links.Previous;
          }
        }
        console.log('-------nextOrPreviousUrl------', nextOrPreviousUrl);
        if (nextOrPreviousUrl && this.dataQueryIdParameter) {
          await this.findNextOrPreviousItems(nextOrPreviousUrl, this.dataQueryIdParameter);
        }
        console.log('-------DataQueryMixiing : optionPaginationEmitter : options------END ');
      }
    },

    itemsPerPageEmitter(itemsPerPage) {
      console.log('-------itemsPerPageEmitter------', itemsPerPage);
    },

  },
};

export default DataQueryMixin;

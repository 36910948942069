/**
 * Mixin to calculate items per page from total available items.
 */

const ItemsPerPageMixin = {

  data() {
    return {
      itemsPerPage: 0,
      itemsPerPageOptions: [],
    };
  },

  methods: {
    calculateItemsPerPage(totalAvailableItems) {
      console.log('------Utility: calculateItemsPerPage -------------: ', totalAvailableItems);
      if (totalAvailableItems && Number.isInteger(totalAvailableItems)) {
        if (totalAvailableItems >= 0 && totalAvailableItems <= 10) {
          this.itemsPerPage = 0;
          this.itemsPerPageOptions = [10, 20, 30, 40, 50, -1];
        } else if (totalAvailableItems > 10 && totalAvailableItems <= 500) {
          this.itemsPerPage = 10;
          this.itemsPerPageOptions = [10, 20, 30, 40, 50, -1];
        } else if (totalAvailableItems > 500 && totalAvailableItems <= 2000) {
          this.itemsPerPage = 50;
          this.itemsPerPageOptions = [50, 100, 150, 200, 250, 300, -1];
        } else if (totalAvailableItems > 2000 && totalAvailableItems <= 5000) {
          this.itemsPerPage = 100;
          this.itemsPerPageOptions = [100, 200, 300, 400, 500, -1];
        } else if (totalAvailableItems > 5000 && totalAvailableItems <= 10000) {
          this.itemsPerPage = 200;
          this.itemsPerPageOptions = [200, 400, 600, 800, 1000, -1];
        } else if (totalAvailableItems > 10000 && totalAvailableItems <= 50000) {
          console.log('------------- this.itemPerPgae 222--------', this.itemsPerPage);
          this.itemsPerPage = 500;
          this.itemsPerPageOptions = [500, 600, 700, 800, 900, 1000, -1];
          console.log('------------- this.itemPerPgae 333--------', this.itemsPerPage);
        } else if (totalAvailableItems > 50000) {
          this.itemsPerPage = 1000;
          this.itemsPerPageOptions = [500, 600, 700, 800, 900, 1000, -1];
        }
      }
    },

  },
};

export default ItemsPerPageMixin;
